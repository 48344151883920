import { GridSortDirection } from '@mui/x-data-grid';
import { CountryCode } from 'libphonenumber-js';

import { SelectOption } from '@/components/common/form/Select/CommonSelect';
import { ErrorCode } from '@/openapi-api/member-service';

export const allowedCountries: CountryCode[] = ['CA', 'US'];

export const sortingOrder = [
  {
    label: 'Lowest to highest',
    value: 'asc' as GridSortDirection,
  },
  {
    label: 'Highest to lowest',
    value: 'desc' as GridSortDirection,
  },
];

export const genderOptions: SelectOption[] = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Others',
    value: 'OTHER',
  },
];

export const roleOptions: SelectOption[] = [
  {
    label: 'Member',
    value: 'MEMBER',
  },
  {
    label: 'Guest',
    value: 'GUEST',
  },
];

export const ErrorMessage: Record<ErrorCode, string> = {
  [ErrorCode.MEMBER_TIER_LEVEL_IS_SET]: 'This tier has already been set',
  [ErrorCode.MEMBER_TIER_LEVEL_IS_HIGHER]:
    'The tier of the reward is lower than the current tier',
  [ErrorCode.CONSUMER_PHONE_INVALID_UPDATE]: 'Phone number invalid update',
  [ErrorCode.CONSUMER_EMAIL_INVALID_UPDATE]: 'Email invalid update',
  [ErrorCode.CONSUMER_EMAIL_EXPIRED_CODE]: 'Code is expired',
  [ErrorCode.CONSUMER_PHONE_PLIVO_INCORRECT_OTP]: 'Incorrect verify code',
  [ErrorCode.CONSUMER_PHONE_PLIVO_EXCEEDED_ATTEMPTS]:
    'You have reached the limit for verification code requests. Please contact support for assistance.',
  [ErrorCode.CONSUMER_PHONE_PLIVO_EXPIRED_SESSION]:
    'You have reached the limit for verification code requests. Please contact support for assistance.',
  [ErrorCode.BUSINESS_USER_EMAIL_INVALID_UPDATE]: 'Email invalid update',
  [ErrorCode.BUSINESS_USER_EMAIL_EXPIRED_CODE]: 'Code is expired',
  [ErrorCode.NO_AVAILABLE_PHONE_NUMBERS_FOUND]:
    'No available phone numbers found',
  [ErrorCode.PHONE_NUMBER_CREATE_FAILED]: 'Phone number create failed',
  [ErrorCode.PHONE_NUMBER_UNRENT_FAILED]: 'Phone number unrent failed',
  [ErrorCode.BUSINESS_CAMPAIGN_SUBSCRIPTION_PLAN]:
    'Please upgrade your subscription plan to send more campaigns',
  [ErrorCode.BUSINESS_CAMPAIGN_LIMIT]:
    'You have reached the limit of campaigns',
  [ErrorCode.BUSINESS_CAMPAIGN_INCORRECT_STATUS_UPDATE]:
    'Incorrect status update',
  [ErrorCode.BUSINESS_CAMPAIGN_COMPLETED]: 'Campaign is completed',
  [ErrorCode.BUSINESS_COLLABORATION_DISABLED]: 'Collaboration is disabled',
  [ErrorCode.BUSINESS_USES_DEFAULT_POINTS]:
    'Business uses default points system',
};

export const TERMS_AND_CONDITION_URL =
  'https://ostana.io/terms-conditions/';
export const PRIVACY_POLICY_URL = 'https://ostana.io/privacy-policy/';
export const OSTANA_MAIN_SITE = 'https://ostana.io/';
export const PEXELS_URL = 'https://images.pexels.com/';
export const DEFAULT_BUSINESS_COVER = '/images/businessInfoCoverDefault.png';
export const REDIRECT_URL_KEY = 'redirectUrl';
export const REFERRAL_CODE = 'referral-code';
export const AUTO_JOIN = 'auto-join';

